document.addEventListener('DOMContentLoaded', () => {
    const burger = document.querySelector('.header__burger');
    const burgerBurger = document.querySelector('.header__burger_burger');
    const burgerClose = document.querySelector('.header__burger_close');

    const menu = document.querySelector('.header__burger_menu_position');

    burger.addEventListener('click', () => {
        menu.classList.toggle('header__burger_menu_active');

        if(burgerBurger.classList.contains('header__burger_img_active')) {
            burgerBurger.classList.remove('header__burger_img_active');
            burgerClose.classList.add('header__burger_img_active');
        } else {
            burgerBurger.classList.add('header__burger_img_active');
            burgerClose.classList.remove('header__burger_img_active');
        }

    })
})